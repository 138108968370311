:root {
  --icons-color: #242c42;
  --inputs-color: #f6f6f9;

  --page-title-space-start: 60px;
  --form-layout-space-start: 14px;
  --page-title-arrow-width: 16px;
  --page-title-arrow-space-end: 0.5rem;
}

* {
  box-sizing: border-box;
}

.w-25 {
  width: 25%;
}

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 15px;
  margin: 0 auto;
}

::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-thumb {
  border: 2px solid #9c9ea5;
  background-color: #9c9ea5;
  border-radius: 30px;
}

::-webkit-scrollbar-track {
  background-color: #e2e2e2;
  border-radius: 30px;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding: 0;
}

.page {
  display: flex;
  height: 100vh;
}

.content {
  background-color: #fafbfc;
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;

  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-width: 0;
  /* ......................................................................................... */
}

.content .body-content,
.side-nav-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-height: 0;
  /* ......................................................................................... */

  position: relative;
  @media screen and (max-width: 768px) {
    padding: 0;
  }

  & > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
}

.steper-container {
  width: 16%;
}

sxw-form {
  flex: 1;
  /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
  min-height: 0;
  /* ......................................................................................... */
  & > .form-container {
    height: calc(100% - 30px);
  }
  .step-content {
    height: 100% !important;
    /* https://stackoverflow.com/questions/36247140/why-dont-flex-items-shrink-past-content-size */
    min-height: 0;
    /* ......................................................................................... */
    display: flex;
    flex-direction: column;
    .content-container {
      height: 100%;
    }
  }
  div.form-buttons {
    padding: 5px 10px !important;
  }
}

.form-container {
  flex: 1;
  padding-top: 4px;
  overflow-x: auto;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.form-container-config {
  height: 100%;
  margin-left: 1rem;
  width: 74%;
  padding-top: 4px;
  @media screen and (max-width: 768px) {
    width: 100%;
  }
}

.summary-container {
  width: 28%;

  .summary-cards {
    padding-bottom: 4px;
  }
}

.filters-container {
  width: 100%;
  height: 60px;
  position: relative;
  z-index: 20;
}

.filter-search {
  margin-left: 25px;
}

sxw-page-title:not(.not-aligned) .path {
  padding-left: 25px !important;
}

sxw-page-title.not-aligned .path {
  padding-left: 0 !important;
}

.table-container {
  flex: 1;
  width: calc(100% - 40px);
  margin: 0 auto 0 4px;
  //overflow: auto;
  overflow-y: auto;

  &::-webkit-scrollbar:horizontal {
    height: 0px;
  }

  &::-webkit-scrollbar:vertical {
    display: none;
  }
}

.footer {
  position: relative;
  width: 100%;
}

.listing-footer {
  position: absolute;
  bottom: 20px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  padding: 0 25px;
}

.input-group {
  padding-bottom: 10px;
}

.input-group.switch {
  width: 50%;
}

.label-style {
  font-weight: 600;
  font-size: 15px;
  margin-bottom: 5px;
  display: block;
}

.login-input {
  .p-inputtext {
    font-size: 13px;
  }

  .error {
    margin: 0px 0px -5px 0px !important;
    padding-left: 10px;
    font-size: 11px;
  }
}

.login-button {
  width: 100%;

  span {
    width: 100%;
  }

  .p-button-label {
    display: none;
  }
}

.no-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.form-tabs-layout {
  padding: 0 0 0 var(--form-layout-space-start);
  height: 100%;
  .form-layout {
    height: 89%;
  }
}

.form-layout {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  height: 100%;
  gap: 3px;

  &:not(.form-tabs-layout .form-layout) {
    padding: 0 0 0 var(--form-layout-space-start);
  }
}

.top-section {
  width: 72%;
  min-height: 70%;
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 768px) {
  .top-section {
    width: 100%;
    height: 70%;
    gap: 12px;
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .table-container {
    width: 100% !important;
  }
  .two-fields {
    flex-direction: column;
  }
  .multi-inputs {
    width: 100%;
    overflow-x: auto;
  }
  .calender-fields {
    display: flex !important;
    flex-direction: column !important;
    gap: 35px !important;
  }
  .steper-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-start;
    padding: 0 4%;
    overflow-x: auto;
    overflow-y: hidden;
  }
  .summary-container {
    width: 100%;
    margin: 0 auto;
    height: 30%;
    padding-inline: 6px;
    .summary-cards {
      height: 100%;
      width: calc(100% - 4px) !important;
      margin: auto !important;
    }
  }
  .form-layout {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: unset !important;
    height: unset !important;
  }
}
