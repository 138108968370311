/* Start Box */
.d-flex {
  display: flex;
}

.flex-column {
  flex-direction: column;
}

.flex-row {
  flex-direction: row;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-between {
  justify-content: space-between;
}

.justify-content-center {
  justify-content: center;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-center {
  align-items: center;
}

.m-0 {
  margin: 0px;
}

.p-0 {
  padding: 0px;
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.flex-row-start {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.flex-row-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.flex-row-end {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.flex-col-between {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.flex-col-start {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.gap-2 {
  gap: 2px;
}

.gap-5 {
  gap: 5px;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.gap-30 {
  gap: 30px;
}

/* End Box */

/* Start Hide */
.d-none {
  display: none;
}

@media (max-width: 768px) {
  .hide-mobile {
    display: none;
  }
}

/* End Hide*/
.h-full {
  height: 100%;
}

.h-5 {
  height: 5rem;
}

.h-10 {
  height: 10rem;
}

.h-15 {
  height: 15rem;
}

.h-20 {
  height: 20rem;
}

.h-30 {
  height: 30rem;
}

.h-44px {
  height: 44px;
}

.w-full {
  width: 100%;
}

.success {
  height: max-content;
  color: #50ba3f;
  background-color: rgb(107 191 119 / 33%);
  padding-inline: 3px;
  font-size: small;
}

.danger {
  height: max-content;
  color: #ef4444;
  padding-inline: 3px;
  background-color: rgba(191, 143, 107, 0.33);
}

.flex-equal {
  flex: 1;
}
.min-w-0 {
  min-width: 0;
}

.switch-input {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 4px;
  width: 50%;
  justify-content: space-between;
}

.mt-20 {
  margin-top: 22px;
}

.sxw-sticky {
  position: sticky;
  top: 0;
}

.sxw-divider {
  width: auto;
  height: 1px;
  background-color: #ececec;
  margin-block: 10px;
}
